import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import authModule from '@/store/auth'
import * as toast from '@/services/toast'
import Animation from '@/components/Animation/index.vue'
import LoginForm from '@/components/LoginForm/LoginForm.vue'
import modalComponent from '@/components/ModalComponent/ModalComponent.vue'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Animation,
    modalComponent,
    LoginForm,
  },
})
export default class Login extends Vue {
  password = ''
  email = ''
  typePassword = 'password'
  iconPassword = 'eye'
  isComponentModalActive = false
  action = 'openModal'
  isLoading = false

  mounted() {
    localStorage.clear()
    ;(this.$refs.username as HTMLElement)?.focus()
    //if (authModule.isAuthenticated) authModule.logout()
    authModule.clearToken()
    const message: string | null = this.$route.query.message as string
    if (message && this.$route.query.nextUrl != '/')
      toast.error(this.$t(message) as string)
  }

  changeView(): void {
    this.typePassword = this.typePassword === 'password' ? 'text' : 'password'
    this.iconPassword = this.iconPassword === 'eye' ? 'eye-off' : 'eye'
  }

  async submit() {
    this.isLoading = true

    try {
      //se consigue el token
      await authModule.login({
        email: this.email,
        password: this.password,
      })
      //se extrae la info del token
      authModule.extractTokenData(JSON.stringify(authModule.AccessToken))
      //si el usuario se encuentra autorizado se procede a acceder al sistema
      if (authModule.AccessToken) {
        //si es la primera vez que se ingresa
        if (authModule.user.is_temp_login) {
          this.$router.push({ path: '/firstLogin' }).catch()
        }

        //Si ya se ha ingresado
        else if (
          authModule.user.groups.map((x: any) => x.name).includes('MEC')
        ) {
          this.$router.push({ path: '/hoshin' }).catch()
        }
        //si tiene rol cms
        else if (
          authModule.user.groups.map((x: any) => x.name).includes('CMS')
        ) {
          this.$router.push({ path: '/cms' }).catch()
        } else {
          //por defecto
          this.$router.push({ path: '/' }).catch()
        }
      }
    } catch (error) {
      toast.error(this.$i18n.t('toast.login_error').toString())
      console.error(error)
    }

    /* try {
      await authModule.login({
        username: this.username,
        password: this.password,
      })
      const path = (
        this.$route.query.nextUrl ? this.$route.query.nextUrl : '/dashboard'
      ) as string
      this.$router.push(path)
    } catch (error) {
      toast.error(this.$i18n.t('toast.login_error').toString())
      console.error(error)
    } */
    this.isLoading = false
  }
  add() {
    this.action = 'create'
  }
  closeForm() {
    this.action = 'openModal'
  }

  // async submit() {
  //   try {
  //     await authModule.login({
  //       username: this.username,
  //       password: this.password,
  //     })
  //
  //     this.$router.push('/hoshin')
  //   } catch (error) {
  //     toast.error(this.$i18n.t('toast.login_error').toString())
  //     console.error(error)
  //   }
  // }
}
